import { createAction } from '@ngrx/store'
import { FleetCustomer, OnsiteContact } from '@boosterfuels/types/types-ts'

export const siteContactsTabActions = {
  SET_FLEET_ACCOUNT_ID: createAction(
    '[Site Contacts Tab] Set Fleet Account ID',
    (fleetAccountId: string) => ({ payload: fleetAccountId })
  ),

  FIND_ONSITE_CONTACTS: createAction(
    '[Site Contacts Tab] Find Onsite Contacts',
    (fleetAccountId: string) => ({
      payload: fleetAccountId,
    })
  ),

  FIND_ONSITE_CONTACTS_SUCCESS: createAction(
    '[Fleet Customer Api] Find Onsite Contacts Success',
    (contacts: OnsiteContact[]) => ({
      payload: contacts,
    })
  ),

  FIND_ONSITE_CONTACTS_ERROR: createAction(
    '[Fleet Customer Api] Find Onsite Contacts Error'
  ),

  FIND_FLEET_CUSTOMERS: createAction(
    '[Site Contacts Tab] Find Fleet Customers',
    (fleetAccountId: string) => ({
      payload: fleetAccountId,
    })
  ),

  FIND_FLEET_CUSTOMERS_SUCCESS: createAction(
    '[Fleet Customer Api] Find Fleet Customers Success',
    (customer: FleetCustomer[]) => ({
      payload: customer,
    })
  ),

  FIND_FLEET_CUSTOMERS_ERROR: createAction(
    '[Fleet Customer Api] Find Fleet Customers Error'
  ),

  OPEN_ADD_CONTACTS_ASIDE: createAction(
    '[Site Contacts Tab] Open Add Contacts Aside'
  ),

  ADD_CONTACTS_ASIDE: createAction(
    '[Site Contacts Tab] Add Contacts Aside',
    (payload: FleetCustomer[]) => ({ payload })
  ),

  OPEN_CONTACTS_ASIDE: createAction('[Site Contacts Tab] Open Contact Aside'),

  CANCEL_CONTACTS_ASIDE: createAction(
    '[Site Contacts Tab] Cancel Contact Aside'
  ),

  BULK_SAVE_CONTACTS: createAction(
    '[Site Contacts Tab] Bulk Save Contacts',
    (contacts: OnsiteContact[]) => ({
      payload: contacts,
    })
  ),

  BULK_SAVE_CONTACTS_SUCCESS: createAction(
    '[Fleet Customer Api] Bulk Save Contacts Success',
    (contacts: OnsiteContact[]) => ({
      payload: contacts,
    })
  ),

  BULK_SAVE_CONTACTS_ERROR: createAction(
    '[Fleet Customer Api] Bulk Save Contacts Error'
  ),

  OPEN_CONTACT_DELETE_MODAL: createAction(
    '[Site Contacts Tab] Open Contact Delete Modal',
    (contactIndex: number) => ({ payload: contactIndex })
  ),

  DELETE_CONTACT: createAction(
    '[Site Contacts Tab] Delete Contact',
    (contactIndex: number) => ({ payload: contactIndex })
  ),

  // TODO: remove when we reintroduce fleet account dropdown for the sites page
  SELECT_FLEET: createAction(
    '[Site Contacts Tab] Select Fleet',
    (payload: { fleetAccountId; fleetCompanyId }) => ({ payload })
  ),
}
